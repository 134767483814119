import React from 'react';
import styled from 'styled-components';
import orbits from '../../assets/images/orbits.svg';
import SocialLink, { SocialLinkType } from '../../components/atoms/SocialLink';
import {
  Header3,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';

export interface BoardPersonProps {
  name: string;
  image: string;
  title?: string;
  text: string;
  social: {
    twitter?: string;
    linkedin?: string;
  };
}

const BoardPerson = (person: BoardPersonProps): JSX.Element => {
  return (
    <SinglePerson>
      <PersonImgWrapper>
        <PersonImg src={person.image} />
      </PersonImgWrapper>
      {person.title && (
        <Upheader $color="tertiary" $textAlign="center">
          {person.title}
        </Upheader>
      )}
      <Header3 $color="tertiary" $textAlign="center">
        {person.name}
      </Header3>
      {person.text && (
        <TextMedium $color="delicateStronger" $textAlign="center">
          {person.text}
        </TextMedium>
      )}
      <PersonSocialWrapper>
        {person.social.linkedin && (
          <SocialLink
            href={person.social.linkedin}
            style="dark"
            type={SocialLinkType.LinkedIn}
          />
        )}
        {person.social.twitter && (
          <SocialLink
            href={person.social.twitter}
            style="dark"
            type={SocialLinkType.Twitter}
          />
        )}
      </PersonSocialWrapper>
    </SinglePerson>
  );
};

const SinglePerson = styled.div`
  text-align: center;
  z-index: ${({ theme }) => theme.layers.base + 1};

  ${Header3} {
    margin-bottom: 12px;
  }

  ${TextMedium} {
    max-width: 260px;
    margin: 0 auto 31px auto;
  }
`;

const PersonImgWrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
  width: 141px;
  height: 141px;
  margin: 0 auto 25px auto;

  :before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 358px;
    height: 358px;

    transform: translate(-50%, -50%);
    background: url('${orbits}') center;
    background-size: contain;
    z-index: ${({ theme }) => theme.layers.base - 1};
  }
`;

const PersonImg = styled.img`
  max-width: 100%;
`;

const PersonSocialWrapper = styled.div`
  display: flex;
  justify-content: center;

  > span {
    margin: 0 5px;
  }
`;

export default BoardPerson;
