import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import IntroSection from '../pages-components/about-us/IntroSection';
import OurStorySection from '../pages-components/about-us/OurStorySection';
import OurVisionSection from '../pages-components/about-us/OurVisionSection';
import BoardSection from '../pages-components/about-us/BoardSection';

const AboutUsPage = (): JSX.Element => {
  return (
    <Layout
      description="NaturalAntibody was founded by a researcher recognized in the field of computational approaches for accelerating antibody engineering and design."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="About Us | Antibody Engineering Company"
    >
      <IntroSection />
      <OurStorySection />
      <OurVisionSection />
      <BoardSection />
    </Layout>
  );
};

export default AboutUsPage;
