import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AboutUsSeparator from '../../assets/images/AboutUsSeparator.inline.svg';
import { AccentedLinkSmall } from '../../components/atoms/AccentedLink';
import {
  Header,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import {
  forScreenAtMost1024pxWide,
  forScreenAtMost1200pxWide,
  forScreenAtMost680pxWide,
  forScreenAtMost800pxWide,
} from '../../styles/mediaQueries';

const OurVisionSection = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const mobileLayout = useMaxWidthBreakpoint(680);

  return (
    <Wrapper>
      <MainContentColumnWrapper>
        <OurVisionWrapper>
          <SeparatorWrapper>
            <AboutUsSeparator />
          </SeparatorWrapper>
          <HeadingWrapper>
            <Upheader $color="delicateAccented">Our vision</Upheader>
            <Header $color="tertiary">
              “Data-driven predictive methods open the doors to faster
              discovery”
            </Header>
          </HeadingWrapper>
          <TextsWrapper>
            <TwoColumnText>
              The potential of antibodies as biotherapeutics is undeniable. But
              to harness it, we need to deepen our understanding of the unique
              features that make them into versatile binders. At
              NaturalAntibody, we believe that computational methods are going
              to unlock the new depths of that potential, helping us to deliver
              new drugs to clinical use faster.
              <CollapsibleText
                isCollapsed={isCollapsed}
                mobileLayout={mobileLayout}
              >
                Today, computational methods are mature enough to facilitate
                therapeutic antibody development and are employed by industry
                leaders. These solutions build upon over three decades of
                research that generated plenty of data on antibody activity.
                Using modern technologies, we can reap benefits from these rich
                data resources and create advanced statistical models to design,
                analyze, and optimize novel antibody therapeutics.
              </CollapsibleText>
            </TwoColumnText>
            {mobileLayout && (
              <AccentedLinkSmall
                $accentColor="secondary"
                $color="delicateAccented"
                as="a"
                onClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ) => {
                  setIsCollapsed(!isCollapsed);
                  e.preventDefault();
                }}
                tabIndex={0}
                to=""
              >
                {isCollapsed ? 'read more' : 'read less'}
              </AccentedLinkSmall>
            )}
          </TextsWrapper>
        </OurVisionWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 137px 0 150px 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondaryPair};
  margin-top: -220px;

  :before {
    content: '';
    position: absolute;
    width: 44%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
  }

  ${forScreenAtMost1200pxWide(css`
    margin-top: -19%;
  `)}

  ${forScreenAtMost800pxWide(css`
    margin-top: -36%;
  `)}

  ${forScreenAtMost680pxWide(css`
    margin-top: -58%;
  `)}
`;

const OurVisionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SeparatorWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 77px;

  ${forScreenAtMost680pxWide(css`
    margin-bottom: 58px;
  `)}
`;

const HeadingWrapper = styled.div`
  width: 100%;
  margin-bottom: 58px;
  padding-left: 100px;

  ${Upheader} {
    margin-bottom: 17px;
  }

  ${Header} {
    max-width: 453px;
  }

  ${forScreenAtMost1024pxWide(css`
    padding-left: 0px;
  `)}

  ${forScreenAtMost680pxWide(css`
    margin-bottom: 44px;
  `)}
`;

const TextsWrapper = styled.div`
  width: 716px;
  margin-left: auto;

  ${forScreenAtMost1200pxWide(css`
    grid-template-columns: 1fr;
  `)}
`;

const TwoColumnText = styled(TextMedium).attrs({
  $color: 'delicateStronger',
})`
  columns: 2 328px;
  column-gap: 60px;
`;

const CollapsibleText = styled('span')<{
  isCollapsed?: boolean;
  mobileLayout?: boolean;
}>`
  ${({ isCollapsed, mobileLayout }) =>
    mobileLayout &&
    css`
      display: block;
      margin-bottom: 20px;
      max-height: ${isCollapsed ? `0px` : `1000px`};
      transition: ${isCollapsed
        ? `max-height 0.5s ease-out`
        : `max-height 0.5s ease-in`};
      overflow: hidden;
    `}
`;

export default OurVisionSection;
