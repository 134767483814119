import styled, { css } from 'styled-components';
import navArrow from '../../assets/images/navArrow.svg';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

export const SwiperNavButton = styled.button<{
  $style: 'dark' | 'light';
}>`
  cursor: pointer;
  margin: 11px 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.accents.footer};
  transition: all 0.2s;

  position: relative;
  z-index: ${({ theme }) => theme.layers.base};

  &:focus-visible {
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      padding: 5px;
      top: -5px;
      left: -5px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.backgrounds.focus};
    }
  }

  ${({ $style }) =>
    $style === 'dark'
      ? css`
          border: 1px solid
            ${({ theme }) => theme.colors.borders.primaryTransparent};

          :hover {
            background: ${({ theme }) =>
              theme.colors.borders.primaryTransparent};
          }
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.accents.footer};

          :hover {
            background: ${({ theme }) => theme.colors.accents.footer};
          }
        `}

  :after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 8px;
    ${({ $style }) =>
      $style === 'dark'
        ? css`
            background: ${({ theme }) => theme.colors.texts.delicateStronger};
          `
        : css`
            background: ${({ theme }) => theme.colors.accents.primary};
          `}
    mask-image: url('${navArrow}');
    transition: background 0.2s;
  }

  &.swiper-prev {
    :after {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-disabled {
    background: transparent !important;
    :after {
      ${({ $style }) =>
        $style === 'dark'
          ? css`
              background: ${({ theme }) =>
                theme.colors.borders.primaryTransparent};
            `
          : css`
              background: ${({ theme }) => theme.colors.accents.footer};
            `}
    }
  }

  ${forScreenAtMost680pxWide(css`
    margin: 0 11px;
  `)}
`;
