import React from 'react';
import styled, { css } from 'styled-components';
import aboutUsBackground from '../../assets/images/aboutUsBackground.jpg';
import aboutUsMobileBackground from '../../assets/images/aboutUsMobileBackground.jpg';
import ScrollLink from '../../components/atoms/ScrollLink';
import { Title, Upheader } from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';

const MainSection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);
  const tabletLayout = useMaxWidthBreakpoint(1024);

  return (
    <Wrapper mobileLayout={mobileLayout}>
      <MainContentColumnWrapper>
        <TextBlock>
          <Upheader $color="delicateAccented">About Us</Upheader>
          <SectionTitle>
            We are unlocking the power of data-driven predictive methods for
            antibody therapeutic industry.
          </SectionTitle>
          {!tabletLayout && (
            <ScrollLink to="#aboutus-content">
              <ScrollIcon />
              Learn more
            </ScrollLink>
          )}
        </TextBlock>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobileLayout: boolean }>`
  ${({ mobileLayout }) =>
    mobileLayout
      ? css`
          background-image: url('${aboutUsMobileBackground}');
        `
      : css`
          background-image: url('${aboutUsBackground}');
        `}
  background-size: cover;
  background-blend-mode: overlay;
  background-position: center;
  position: relative;
  min-height: 860px;

  :before {
    content: '';
    position: absolute;
    width: 85%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
  }

  ${forScreenAtMost680pxWide(css`
    min-height: 700px;

    :before {
      width: 50%;
    }
  `)}
`;

const TextBlock = styled.div`
  padding: 297px 0 283px 0;

  ${forScreenAtMost680pxWide(css`
    padding: 174px 0 0 0;
  `)}
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 553px;
  margin: 7px 0 25px;
`;

export default MainSection;
