import React from 'react';
import styled, { css } from 'styled-components';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import {
  Header1,
  TextMedium,
} from '../../components/atoms/Typography/Typography';
import orbits from '../../assets/images/orbits.svg';
import ourStory from '../../assets/images/ourStory@2x.png';
import ourStoryMobile from '../../assets/images/ourStoryMobile@2x.png';
// import casLogo from '../../assets/images/casLogo.png'; // TODO: temporary
// import OxfordLogo from '../../assets/images/OxfordLogo.inline.svg';
// import SduLogo from '../../assets/images/SduLogo.inline.svg';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost800pxWide,
  forScreenAtMost1024pxWide,
  forScreenAtMost1200pxWide,
} from '../../styles/mediaQueries';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';

const OurStorySection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);

  return (
    <Wrapper id="aboutus-content">
      <MainContentColumnWrapper>
        <OurStoryWrapper>
          <OurStoryImage src={mobileLayout ? ourStoryMobile : ourStory} />
          <OurStoryContent>
            <StyledHeader>Our story</StyledHeader>
            <StyledText>
              NaturalAntibody was founded by Konrad Krawczyk, who spent the last
              decade researching computational approaches for enhancing the
              design of antibody-based therapeutics. The collaborations with
              leading academic institutions and pharmaceutical companies opened
              his eyes to the value of antibody data. Researchers can use this
              data to create advanced computational models promising to
              accelerate therapeutic antibody design and drug discovery
              processes.
              <br />
              <br />
              This provided the foundation for NaturalAntibody, a company that
              focuses on collecting, generating, and analyzing antibody data to
              enable end-to-end computational discovery of novel
              biotherapeutics.
            </StyledText>
            <LogosWrapper>
              {/* <OxfordLogo /> */}
              {/* <SduLogo /> */}
              {/* <img src={casLogo} width="80" /> */}
            </LogosWrapper>
          </OurStoryContent>
        </OurStoryWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 174px 0 0 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  overflow: hidden;

  ${forScreenAtMost680pxWide(css`
    padding-top: 120px;
  `)}
`;

const OurStoryWrapper = styled.div`
  display: flex;
  gap: 44px;

  ${forScreenAtMost1200pxWide(css`
    gap: 0;
  `)}

  ${forScreenAtMost800pxWide(css`
    flex-direction: column-reverse;
    align-items: center;
    gap: 60px;
  `)}
`;

const OurStoryImage = styled.img`
  margin-top: 136px;
  max-width: 50%;
  position: relative;
  z-index: ${({ theme }) => theme.layers.base + 1};

  ${forScreenAtMost1024pxWide(css`
    margin-top: 186px;
    margin-left: -50px;
  `)}

  ${forScreenAtMost800pxWide(css`
    margin-top: 0;
    margin-left: 0;
    max-width: 100%;
  `)}

  ${forScreenAtMost680pxWide(css`
    max-width: calc(100% + 70px);
  `)}
`;

const OurStoryContent = styled.div`
  position: relative;
  max-width: 454px;

  :before {
    content: '';

    position: absolute;
    top: 20px;
    left: 10px;

    width: 409px;
    height: 409px;

    transform: translate(-50%, -50%);
    background: url('${orbits}');
  }
`;

const StyledHeader = styled(Header1).attrs({ $color: 'secondary' })`
  position: relative;
  margin-bottom: 30px;
`;

const StyledText = styled(TextMedium).attrs({ $color: 'delicateStronger' })`
  position: relative;
  padding-left: 30px;
  margin: 0 0 58px;

  ${forScreenAtMost1024pxWide(css`
    margin-bottom: 30px;
  `)}

  ${forScreenAtMost800pxWide(css`
    margin-bottom: 0;
  `)}
`;

const LogosWrapper = styled.div`
  padding-left: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 50px;
  }

  ${forScreenAtMost1024pxWide(css`
    > *:not(:last-child) {
      margin-right: 25px;
    }
  `)}

  ${forScreenAtMost800pxWide(css`
    display: none;
  `)}
`;

export default OurStorySection;
