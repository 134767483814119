import React from 'react';
import styled from 'styled-components';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import { Header1 } from '../../components/atoms/Typography/Typography';
import { SwiperNavButton } from '../../components/atoms/SwiperNavButton';
import BoardPerson, { BoardPersonProps } from './BoardPerson';

import konradImage from '../../assets/images/konrad@2x.png';
import michalImage from '../../assets/images/michal@2x.png';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';

const board: BoardPersonProps[] = [
  {
    name: 'Konrad Krawczyk, PhD.',
    title: 'President of the Board',
    image: konradImage,
    text: 'The architect behind the leading computational antibody platform at the University of Oxford, Konrad is an Assistant Professor at the University of Southern Denmark and CEO at NaturalAntibody. He is a recognized authority in computational antibody design and analysis, having served as a consultant on bioinformatic antibody issues to top research institutions and pharmaceutical companies.',
    social: {
      twitter: '',
      linkedin: 'https://www.linkedin.com/in/konrad-krawczyk-3111a2211/',
    },
  },
  {
    name: 'Michał Krawczyk',
    title: 'Member of the Board',
    image: michalImage,
    text: 'Michał is a serial entrepreneur and co-founder with several successful projects under his belt, such as Patronite and Inbio. He has vast experience in funding and scaling multiple tech ventures in highly specialized areas such as machine learning and cybersecurity. Michał holds a Master’s degree in Economics and Finance at the University of Warsaw.',
    social: {
      twitter: '',
      linkedin: 'https://www.linkedin.com/in/micha%C5%82-krawczyk-0b68b2135/',
    },
  },
];

const BoardSection = (): JSX.Element => {
  const tabletLayout = useMaxWidthBreakpoint(800);
  SwiperCore.use([Autoplay, EffectFade, Navigation]);

  return (
    <Wrapper>
      <MainContentColumnWrapper>
        <Header1 $color="secondary" $textAlign="center">
          Executive Board
        </Header1>
        {tabletLayout ? (
          <BoardPeopleMobileWrapper>
            <Swiper
              autoplay={{
                delay: 7000,
              }}
              effect={'fade'}
              fadeEffect={{ crossFade: true }}
              navigation={{
                prevEl: '.swiper-prev',
                nextEl: '.swiper-next',
              }}
            >
              {board.map((person, index) => (
                <SwiperSlide key={index}>
                  <BoardPerson {...person} />
                </SwiperSlide>
              ))}
              <SwiperNavWrapper slot="container-start">
                <SwiperNavButton $style="dark" className="swiper-prev" />
                <SwiperNavButton $style="dark" className="swiper-next" />
              </SwiperNavWrapper>
            </Swiper>
          </BoardPeopleMobileWrapper>
        ) : (
          <BoardPeopleDesktopWrapper>
            {board.map((person, index) => (
              <BoardPerson key={index} {...person} />
            ))}
          </BoardPeopleDesktopWrapper>
        )}
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 121px 0 292px 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  overflow: hidden;
`;

const BoardPeopleDesktopWrapper = styled.div`
  margin-top: 125px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const BoardPeopleMobileWrapper = styled.div`
  .swiper-container {
    padding: 110px 0 120px 0;
    margin: -20px -15px 0 -15px;
  }
`;

const SwiperNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 155px;
  z-index: ${({ theme }) => theme.layers.base + 2};

  button {
    margin: 0;
  }
`;

export default BoardSection;
